var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":[],"search":_vm.search,"filter":_vm.filter},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Tilbake til arbeidsflate","icon":"mdi-arrow-left"},on:{"handleClick":function($event){return _vm.$router.push("/home/")}}}),_vm._v(" Mine ordre ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i ordre","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.isTransfered",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Ja" : "Nei")+" ")]}},{key:"item.payee",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getName(value))+" ")]}},{key:"item.student",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getName(value))+" ")]}},{key:"item.courseId",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCName(value))+" ")]}},{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$router.push('/')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}])},[_c('span',[_vm._v("Gå til ordre")])])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }