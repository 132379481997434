


















































import { mapGetters, mapActions } from "vuex";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { VSwitch } from "vuetify/lib";
// import ToolTipButton from "@/components/shared/button/BaseTooltipButton.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import { getContactName } from "@/shared/helpers/contactHelpers";
import { getCourseName } from "@/shared/helpers/courseHelpers";
import { defineComponent } from "@vue/composition-api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { api } from "@/api/api";
import { ApiGetCustomerDto } from "@/api/generated/Api";

interface ComponentData {
  search: string;
  customers: ApiGetCustomerDto[];
}

export default defineComponent({
  name: "PendingOrdersPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
  },
  data(): ComponentData {
    return {
      search: "",
      customers: [],
    };
  },
  computed: {
    ...mapGetters("contacts", ["getPersons"]),
    ...mapGetters("courses", ["getCourses"]),
    getCName() {
      return (value: any) => getCourseName(this, value)?.courseName;
    },
    getName() {
      return (value: any) =>
        `${getContactName(value, this.customers)?.customer.firstName} ${
          getContactName(value, this.customers)?.customer.lastName
        }`;
    },
    headers() {
      return [
        { text: "Kurs", value: "courseId" },
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Betalingsmottaker",
          value: "payee",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Beløp",
          value: "amount",
        },
        {
          text: "Overført til ECIT",
          value: "isTransfered",
        },
        { text: "Status fra Visma Global", value: "statusVismaGlobal" },
        { text: "Handlinger", value: "actions" },
      ];
    },
    filter() {
      return [
        {
          component: VSwitch,
          value: "isMine",
          staticClass: "mx-3",
          default: true,
          attrs: {
            label: "Vis kun mine",
            inset: true,
          },
          apply: (value: any, model: any) => model || value,
        },
      ];
    },
  },
  methods: {
    ...mapActions("courses", ["fetchCourses"]),
    async getCustomers() {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        this.customers = (await api.customer.getCustomerPersonsAsync()).data;
      });
    },
  },
  async mounted() {
    this.getCustomers();
    await (this as any).fetchCourses();
  },
});
